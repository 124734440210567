import axios from "axios"
import Config from "../config"

const instrument = {
  namespaced: true,
  state: {
    debug: false,
    personInstruments: [],
    instruments: [],
    colorCodes: [],
    datastreamGroupsFilter: [],
    assignedDqrInfo: [],
    dqprs: [],
    filters: [],
    tableViewFilters: [],
    dqas: [],
    dqrDetailURL: Config.instrumentOperationsDashboard.dqrDetailURL,
    dqaDetailURL: Config.instrumentOperationsDashboard.dqaDetailURL,
    dqprDetailURL: Config.instrumentOperationsDashboard.dqprDetailURL,
    cmrURL: Config.instrumentOperationsDashboard.cmrURL,
    pmrURL: Config.instrumentOperationsDashboard.pmrURL,
    instURL: Config.instrumentOperationsDashboard.instURL,
    showDQR: true,
    showDQA: true,
    showCM: true,
    showPM: true,
    masterStartDate: null,
    masterEndDate: null,
    datesCleared: false,
    componentKey: 0,
    fullPersonInstruments: [],
    selectedInstruments: [],
  },
  getters: {   
    personInstruments(state) {
      return state.personInstruments
    },
    personAvailableSites(state) {
      const siteCodes = state.fullPersonInstruments.map(instr => instr.siteCode)
      const unique = Array.from(new Set(siteCodes))
      console.log(unique)
      return unique
    },

    personAvailableFacilities(state) {
      // Use fullPersonInstruments instead of personInstruments
      const facilityCodes = state.fullPersonInstruments.map(instr => instr.facilityCode)
      const unique = Array.from(new Set(facilityCodes))
      return unique
    },
    datastreamGroupsFilter(state) {
      return state.datastreamGroupsFilter
    },
    getAssignedDqrInfo(state) {
      return state.assignedDqrInfo
    },
    getDqaInfo(state) {
      return state.dqas
    },
    getDqprInfo(state) {
      return state.dqprs
    },
    getTableViewFilters(state) {
      return state.tableViewFilters
    },
    
    //Environment specifc URLs for Assigned DQRs, DQAs, DQPRs
  
    getDQRDetailURL(state) {
      return state.dqrDetailURL
    },
    getDQADetailURL(state) {
      return state.dqaDetailURL
    },
    getDQPRDetailURL(state) {
      return state.dqprDetailURL
    },
    getCmrURL(state) {
      return state.cmrURL
    },
    getPmrURL(state) {
      console.log(state.pmrURL)
      return state.pmrURL
    },
    getInstURL(state) {
      console.log(state.instURL)
      return state.instURL
    },
    showDQR(state) {
      return state.showDQR
    },
    showDQA(state) {
      return state.showDQA
    },
    showCM(state) {
      return state.showCM
    },
    showPM(state) {
      return state.showPM
    },
    masterStartDate(state) {
      return state.masterStartDate
    },
    masterEndDate(state) {
      return state.masterEndDate
    },
    datesCleared(state) {
      return state.datesCleared
    },
    componentKey(state) {
      return state.componentKey
    },
  },
  mutations: {
    removePersonInstrumentFromFull(state, nodeId) {
      state.fullPersonInstruments = state.fullPersonInstruments.filter(instr => instr.nodeId !== nodeId)
    },
    setFullPersonInstruments(state, instruments) {
      state.fullPersonInstruments = instruments
    },
    setComponentKey(state, newKey) {
      state.componentKey = newKey
    },
    updatePersonInstruments(state, instr) {
      state.personInstruments = instr
    },
    removePersonInstrument(state, nodeId) {
      state.personInstruments = state.personInstruments.filter((instr) => instr.nodeId !== nodeId)
    },
    updatePersonInstrument(state, instr) { //instr should be an ARRAY of one instrument
      const newInstrList = state.personInstruments.map((obj)=> { 
        if (obj.nodeId == instr.nodeId) {
          obj = instr
          return obj
        } else {
          return obj
        }
      })
      state.personInstruments = newInstrList   
    },
    updateInstruments(state, instr) {
      state.instruments.push(instr)
    },
    setColorCodes(state, codes) {
      state.colorCodes = codes
    },
    setAssignedDqrInfo(state, items) {
      state.assignedDqrInfo = items
    },
    setDqaInfo(state, items) {
      state.dqas = items
    },
    setDqprInfo(state, items) {
      state.dqprs = items
    },
    addTableViewFilter(state, table) {
      if (!state.tableViewFilters.includes(table)) {
        state.tableViewFilters.push(table)
      }
    },
    removeTableViewFilter(state, table) {
      const index = state.tableViewFilters.indexOf(table)
      if (index > -1) {
        state.tableViewFilters.splice(index, 1)
      }
    },
    updateDqas(state, items) {
      state.dqas = items
    },
    setDQR(state, value) {
      state.showDQR = value
    },
    setDQA(state, value) {
      state.showDQA = value
    },
    setCM(state, value) {
      state.showCM = value
    },
    setPM(state, value) {
      state.showPM = value
    },
    //setMasterStartDate
    setMasterStartDate(state, date) {
      state.masterStartDate = date
    },
    setMasterEndDate(state, date) {
      state.masterEndDate = date
    },
    clearMasterDates(state) {
      state.masterStartDate = null
      state.masterEndDate = null
      state.datesCleared = true
    },
    resetDatesCleared(state) {
      state.datesCleared = false
    },
  },
  actions: {
    updateComponentKey({ commit }, newKey) {
      commit("setComponentKey", newKey)
    },
    resetDatesCleared({ commit }) {
      commit("resetDatesCleared")
    },
    clearMasterDates({ commit }) {
      commit("clearMasterDates")
    },
    setMasterStartDate({ commit }, date) {
      commit("setMasterStartDate", date)
    },
    setMasterEndDate({ commit }, date) {
      commit("setMasterEndDate", date)
    },
    toggleDQR({ commit, state }) {
      commit("setDQR", !state.showDQR)
    },
    toggleDQA({ commit,state }) {
      commit("setDQA", !state.showDQA)
    },
    toggleCM({ commit,state }) {
      commit("setCM", !state.showCM)
    },
    togglePM({ commit,state }) {
      commit("setPM", !state.showPM)
    },
    addTableViewFilter({ commit }, table) {
      commit("addTableViewFilter", table)
    },
    removeTableViewFilter({ commit }, table) {
      commit("removeTableViewFilter", table)
    },
    retrieveInstrumentsBySiteAndFacility({commit, state, rootState}, {siteCode, facilityCode}) {
      //if there is no sitecode make it null 
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query: `{
            getActiveInstruments(facilityCode: "${facilityCode}", siteCode: "${siteCode}") {
              nodes {
                facilityCode
                instrumentCode
                instrumentName
                siteCode
              }
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const recs = response.data.data.getActiveInstruments.nodes
          commit("updateInstruments", recs)
          return recs
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
      
    },

    retrieveInstrumentsByPersonId({commit, dispatch, state, rootState}) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`{
            allOperationsDashboardOpsUserInstruments (filter: {personId: {equalTo: ${rootState.auth.personId}}}) {
              nodes {
                siteCode
                facilityCode
                instrumentClassCode
                visible
                nodeId
              }
            }
          }   
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const recs = response.data.data.allOperationsDashboardOpsUserInstruments.nodes
          commit("updatePersonInstruments", JSON.parse(JSON.stringify(recs)))
          
          return dispatch("retrieveAllPersonInstruments", recs)
            .then(() => {
              return recs
            })
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveInstrumentsByPersonIdWithFilters({commit, state, rootState}, {siteCode=null, facilityCode=null}) {
      if (false/*state.personInstruments.length > 0*/) {
        return state.personInstruments
      } else {
        let filters = `filter: {personId: {equalTo: ${rootState.auth.personId}}`
        if (siteCode !== null) {
          filters += `, siteCode: {equalTo: "${siteCode}"}`
        }
        if (facilityCode !== null) {
          filters += `, facilityCode: {equalTo: "${facilityCode}"}`
        }
        filters += "}"
        const queryStr = `{
          allOperationsDashboardOpsUserInstruments (${filters}) {
            nodes {
              siteCode
              facilityCode
              instrumentClassCode
              visible
              nodeId
            }
          }
        }   
        `
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query: queryStr },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allOperationsDashboardOpsUserInstruments.nodes
            commit("updatePersonInstruments", JSON.parse(JSON.stringify(recs)))
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      }
    },
    updatePersonInstruments({ commit, dispatch, rootState }, { facilityCode, instrumentClassCode, siteCode, visible }) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation {
            createOperationsDashboardOpsUserInstrument(
              input: {
                operationsDashboardOpsUserInstrument: {
                  facilityCode: "${facilityCode}", 
                  instrumentClassCode: "${instrumentClassCode}", 
                  personId: ${rootState.auth.personId},
                  siteCode: "${siteCode}",
                  visible: ${visible}
                }
              }
            ) { 
              operationsDashboardOpsUserInstrument {
                personId
                siteCode
                facilityCode
                instrumentClassCode
                visible
                nodeId
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return dispatch("retrieveInstrumentsByPersonId")
        })
        .catch((error) => {
          console.error("Error:", error)
          throw error
        })
    },    
    retrieveAllFacilities({commit, state, rootState}, siteCode="") {
      let siteFilter = ""
      if (siteCode != "") {
        siteFilter = `(filter: {siteCode: {equalTo: "${siteCode}" }})`
      } 
      const queryStr = `{
        allFacilityInfos ${siteFilter} {
          nodes {
            facilityCode
            facilityName
            siteCode
          }
        }
      }   
      `
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query: queryStr,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          // console.log(response.data.data.allFacilityInfos.nodes)
          return response.data.data.allFacilityInfos.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },   
    deletePersonInstrument({ commit, dispatch, rootState }, nodeId) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              deleteOperationsDashboardOpsUserInstrument(
                input: { nodeId: "${nodeId}" }
              ) {
                deletedOpsUserInstrumentId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          commit("removePersonInstrument", nodeId)
          commit("removePersonInstrumentFromFull", nodeId)
          return dispatch("retrieveInstrumentsByPersonId")
        })
        .catch((error) => {
          console.error("Error in deletePersonInstrument:", error)
          throw error
        })
    },
    retrieveAllPersonInstruments({ commit, rootState }, recs) {
      if (recs.length === 0) {
        commit("updatePersonInstruments", [])
        return Promise.resolve([])
      }
      let query = `{ allDatastreamInfos(filter: { or: [`
      recs.forEach(itm => {
        let filter = "{"
        if (itm.instrumentClassCode && !itm.siteCode && !itm.facilityCode) {
          filter += `instrumentClassCode: { equalTo: "${itm.instrumentClassCode}" }\n`
        } else {
          if (itm.siteCode) {
            filter += `siteCode: { equalTo: "${itm.siteCode}" }\n`
          }
          if (itm.facilityCode) {
            filter += `facilityCode: { equalTo: "${itm.facilityCode}" }\n`
          }
          if (itm.instrumentClassCode) {
            filter += `instrumentClassCode: { equalTo: "${itm.instrumentClassCode}" }\n`
          }
        }
        filter += "}\n"
        query += filter
      })
    
      query += `] }) {
        nodes {
          facilityCode
          instrumentClassCode
          siteCode
          visible
        }
      }}`
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: { query },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then((response) => {
          const responseRecs = response.data.data.allDatastreamInfos.nodes
          console.log("Total records from GraphQL:", responseRecs.length)
          const seen = new Set()
          const deduplicatedRecs = responseRecs.filter(rec => {
            const key = `${rec.siteCode}-${rec.facilityCode}-${rec.instrumentClassCode}`
            const isDuplicate = seen.has(key)
            seen.add(key)
            return !isDuplicate
          })
          console.log("After deduplication:", deduplicatedRecs.length)
    
          const displayRecs = []
          const fullRecs = []
          const processedCombos = new Set()
    
          recs.forEach(rec => {
            if (rec.instrumentClassCode && !rec.siteCode && !rec.facilityCode) {
              const instrumentOnlyRecs = deduplicatedRecs.filter(r => r.instrumentClassCode === rec.instrumentClassCode)
              instrumentOnlyRecs.forEach(facility => {
                fullRecs.push({
                  ...facility,
                  nodeId: rec.nodeId,
                  visible: typeof rec.visible !== "undefined" ? rec.visible : true
                  ,
                })
              })
    
              displayRecs.push({
                facilityCode: "",
                instrumentClassCode: rec.instrumentClassCode,
                siteCode: "",
                nodeId: rec.nodeId,
                visible: typeof rec.visible !== "undefined" ? rec.visible : true
                ,
              })
              return
            }
            if (rec.siteCode && rec.facilityCode && !rec.instrumentClassCode) {
              displayRecs.push({
                siteCode: rec.siteCode,
                facilityCode: rec.facilityCode,
                instrumentClassCode: "",
                nodeId: rec.nodeId,
                visible: typeof rec.visible !== "undefined" ? rec.visible : true
                ,
              })
              const siteFacRecs = deduplicatedRecs.filter(r => r.siteCode === rec.siteCode && r.facilityCode === rec.facilityCode)
              siteFacRecs.forEach(inst => {
                fullRecs.push({
                  siteCode: rec.siteCode,
                  facilityCode: rec.facilityCode,
                  instrumentClassCode: inst.instrumentClassCode,
                  nodeId: rec.nodeId,
                  visible: typeof rec.visible !== "undefined" ? rec.visible : true
                  ,
                })
              })
              return
            }
            const comboKey = `${rec.siteCode}-${rec.instrumentClassCode}`
    
            if (rec.facilityCode) {
              const displayRec = {
                facilityCode: rec.facilityCode,
                instrumentClassCode: rec.instrumentClassCode,
                siteCode: rec.siteCode,
                nodeId: rec.nodeId,
                visible: typeof rec.visible !== "undefined" ? rec.visible : true
                ,
              }
    
              if (!displayRecs.some(ele => ele.siteCode === displayRec.siteCode &&
                ele.facilityCode === displayRec.facilityCode &&
                ele.instrumentClassCode === displayRec.instrumentClassCode)) {
                displayRecs.push(displayRec)
                fullRecs.push(displayRec)
              }
            } else if (!processedCombos.has(comboKey)) {
              processedCombos.add(comboKey)
    
              displayRecs.push({
                facilityCode: "",
                instrumentClassCode: rec.instrumentClassCode,
                siteCode: rec.siteCode,
                nodeId: rec.nodeId,
                visible: typeof rec.visible !== "undefined" ? rec.visible : true
                ,
              })
              const matchingFacilities = deduplicatedRecs.filter(r => r.siteCode === rec.siteCode && r.instrumentClassCode === rec.instrumentClassCode)
              matchingFacilities.forEach(facility => {
                fullRecs.push({
                  facilityCode: facility.facilityCode,
                  instrumentClassCode: rec.instrumentClassCode,
                  siteCode: rec.siteCode,
                  nodeId: rec.nodeId,
                  visible: typeof rec.visible !== "undefined" ? rec.visible : true
                  ,
                })
              })
            }
          })
          const uniqueFullRecs = []
          const seenFullRecs = new Set()
          fullRecs.forEach(rec => {
            const key = `${rec.siteCode}-${rec.facilityCode}-${rec.instrumentClassCode}`
            if (!seenFullRecs.has(key)) {
              seenFullRecs.add(key)
              uniqueFullRecs.push(rec)
            }
          })
    
          console.log("displayRecs length:", displayRecs.length)
          console.log("fullRecs before dedup:", fullRecs.length)
          console.log("fullRecs after dedup:", uniqueFullRecs.length)
    
          commit("updatePersonInstruments", JSON.parse(JSON.stringify(displayRecs)))
          commit("setFullPersonInstruments", JSON.parse(JSON.stringify(uniqueFullRecs)))
    
          return displayRecs
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    updatePersonInstrument({commit, state, rootState}, instr) {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`mutation {
          updateOperationsDashboardOpsUserInstrument(
            input: {
              nodeId: "${instr.nodeId}", 
              operationsDashboardOpsUserInstrumentPatch: {
                facilityCode: "${instr.facilityCode}", 
                instrumentClassCode: "${instr.instrumentClassCode}", 
                personId: ${rootState.auth.personId}, 
                siteCode: "${instr.siteCode}", 
                visible: ${instr.visible}
              }
            }
          )
          {
            clientMutationId
            operationsDashboardOpsUserInstrument {
              facilityCode
              instrumentClassCode
              nodeId
              personId
              siteCode
              visible
            }
          }
        }   
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          const instr = response.data.data.updateOperationsDashboardOpsUserInstrument.operationsDashboardOpsUserInstrument
          commit("updatePersonInstrument", JSON.parse(JSON.stringify(instr)))
          return instr
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveInstrumentDataStreamInfos({state, rootState}, payload) {
      if (state.debug) console.log("retrieveInstrumentDataStreamInfos: ", payload)
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`query retrieveInstrumentDataStreamInfos($siteCode: String!, $facilityCode: String!, $instrumentClassCode: String!) {
          allDatastreamInfos(
            filter: {
              siteCode: { equalTo: $siteCode }
              facilityCode: { equalTo: $facilityCode }
              instrumentClassCode: { equalTo: $instrumentClassCode }
              visible: {equalTo: "Y"}
              dataAvailable: {equalTo: "Y"}
            }
          ) {
            nodes {
              dataLevelCode
              datastream
              endDate
              facilityCode
              instrumentClassCode
              nodeId
              siteCode
              startDate
              visible
              dataAvailable
            }
          }
        }   
        `,
        variables: {
          siteCode: payload.siteCode,
          facilityCode: payload.facilityCode,
          instrumentClassCode: payload.instrumentClassCode,
        },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          return response.data.data.allDatastreamInfos.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveTimelineData({ commit, state, rootState }, payload) {
      const orFilters =[{
        site: { equalTo: payload.site },
        facilityCode: { equalTo: payload.facility },
        instrument: { equalTo: payload.instrument },
      }]
      const constructFilterItem = obj => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
        }
        return `{${parts.join(", ")}}`
      }
      const queryFiltersArray = orFilters.map(constructFilterItem)
      const queryFilters = `filter: { 
        or: [${queryFiltersArray.join(", ")}],
      }`
      const dataStreamValues = []

      payload.datastream.forEach(ds => {
        dataStreamValues.push(`"${ds.datastream}"`)
      })
      const dataStreamInQuery = `filter: { datastream: { in: [${dataStreamValues.join(", ")}] } }`

      const cmrFilters =[{
        reportSiteCode: { equalTo: payload.site },
        reportFacilityCode: { equalTo: payload.facility },
        instrumentCode: { equalTo: payload.instrument },
      }]
      const cmrFiltersArray = cmrFilters.map(constructFilterItem)
      const cmrQueryFilters = `filter: { 
        or: [${cmrFiltersArray.join(", ")}],
      }`
      const pmrFilters =[{
        reportSiteCode: { equalTo: payload.site },
        reportFacilityCode: { equalTo: payload.facility },
        reportInstrumentClass: { equalTo: payload.instrument },
      }]
      const pmrFiltersArray = pmrFilters.map(constructFilterItem)
      const pmrQueryFilters = `filter: { 
        or: [${pmrFiltersArray.join(", ")}],
      }`     
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`{
          dqas: allDqaDqalocs(
            ${queryFilters}
          ) {
            totalCount
            nodes {
              dqaDqaByDqano {
                dqaStartdate
                dqaEnddate
                dqano
              }
              status
            }
          }
          dqrs: allPifcardqr2VarnameMetrics(
            ${dataStreamInQuery}
          ) {
            nodes {
              pifcardqr2DqrColorCodeByMetricValue {
                codeColor
                codeDesc
                longDefinition
              }
              id
              datastream
              endDate
              startDate
              varName 
            }
          }
          cmrs: allCmrReports (
            ${cmrQueryFilters}
          ) {
            nodes {
              instrumentCode
              nodeId
              reportData
              reportDataType
              reportFacilityCode
              reportId
              reportKey
              reportSiteCode
              reportStateCode
              reportUrl
              reportedDate
              reporterId
            }
          }
          pmrs: allPmrReports (
            ${pmrQueryFilters}
          ) {
            nodes {
              nodeId
              reportData
              reportDataType
              reportFacilityCode
              reportFrequencyCode
              reportGroupId
              reportId
              reportInstrumentClass
              reportKey
              reportSiteCode
              reportStateCode
              reportUrl
              reportedDate
              reporterId
            }
          }
        }   
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          var dqrsTmp = [], dqrs = []

          response.data.data.dqrs.nodes.forEach( dqr => {
            if (dqrsTmp.indexOf(dqr.id) == -1) {
              dqrsTmp[dqr.id] = []
            }
            if (dqrsTmp[dqr.id].indexOf(dqr.startDate + dqr.endDate) == -1) {
              dqrsTmp[dqr.id][dqr.startDate + dqr.endDate] = dqr
            }
          })
          Object.keys(dqrsTmp).forEach(pKey => {
            Object.keys(dqrsTmp[pKey]).forEach(dKey => {
              dqrs.push(dqrsTmp[pKey][dKey])
            })
          })
          return { dqas: response.data.data.dqas.nodes, dqrs, cmrs:response.data.data.cmrs.nodes, pmrs: response.data.data.pmrs.nodes }
          // response.data.data.allPifcardqr2VarnameMetrics.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveColorCodes({commit, state, rootState}) {
      if (state.colorCodes.length > 0) {
        return state.colorCodes
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query:`{
          allPifcardqr2DqrColorCodes {
            nodes {
              codeColor
              codeDesc
              codeNum
              longDefinition
              shortDefinition
            }
          }
        }   
        `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allPifcardqr2DqrColorCodes.nodes
            commit("setColorCodes", recs)
            return recs
          })
          .catch(error => {
            commit("setColorCodes", [])
            console.error("Error:", error)
            return []
          })
      }
    },
    retrieveAssignedDqrInfo({ rootState }, { filters }) {
      if (filters.length === 0) {
        return Promise.resolve([])
      }
      const orFilters = filters.map(item => {
        const obj = {}
        if (item.siteCode) obj.siteCode = { equalTo: item.siteCode }
        if (item.facilityCode) obj.facilityCode = { equalTo: item.facilityCode }
        if (item.instrumentClassCode) obj.instrumentClassCode = { equalTo: item.instrumentClassCode }
        return obj
      })
      const constructFilterItem = (obj) => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: {equalTo: "${obj[key].equalTo}"}`)
        }
        return `{${parts.join(", ")}}`
      }
      const queryFiltersArray = orFilters.map(constructFilterItem)
      const queryFilters = `filter: { 
      prbReviewed: {equalTo: "N"}
      warehouse: {equalTo: "N"}
      or: [${queryFiltersArray.join(", ")}] }`
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            {
              allDqrsWithStatusDetailsVws(${queryFilters}) {
                nodes {
                  facilityCode
                  id
                  instrumentClassCode
                  siteCode
                  statusDesc
                  subject
                  status
                  prbReviewed
                  warehouse

                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => response.data.data.allDqrsWithStatusDetailsVws.nodes)
        .catch(error => {
          console.error("Error retrieving Assigned DQR Info:", error)
          throw error
        })
    },
    retrieveDqaInfo({ rootState }, { filters }) {
      if (filters.length === 0) {
        return Promise.resolve([])
      }
      const orFilters = filters.map(item => {
        const obj = {}
        if (item.site) obj.site = { equalTo: item.site }
        if (item.facilityCode) obj.facilityCode = { equalTo: item.facilityCode }
        if (item.instrument) obj.instrument = { equalTo: item.instrument }
        return obj
      })
      const constructFilterItem = (obj) => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: { equalTo: "${obj[key].equalTo}" }`)
        }
        return `{ ${parts.join(", ")} }`
      }
      const queryFiltersArray = orFilters.map(constructFilterItem)
      const queryFilters = `filter: { or: [${queryFiltersArray.join(", ")}] }`
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            {
              allDqaDqalocs(${queryFilters}) {
                nodes {
                  dqano
                  comment
                  facilityCode
                  instrument
                  site
                  status
                  dqaDqaByDqano {
                    dqaEnddate
                    dqaStartdate
                  }
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => response.data.data.allDqaDqalocs.nodes)
        .catch(error => {
          console.error("Error retrieving DQA Info:", error)
          throw error
        })
    },
    retrieveUpcomingCalibrations({ rootState }, { filters }) {
      if (filters.length === 0) {
        return Promise.resolve([])
      }
    
      const orFilters = filters.map(item => {
        const obj = {}
        if (item.site) obj.siteCode = { equalTo: item.site }
        if (item.facility) obj.facilityCode = { equalTo: item.facility }
        return obj
      })
      const constructFilterItem = (obj) => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: { equalTo: "${obj[key].equalTo}" }`)
        }
        return `{ ${parts.join(", ")} }`
      }
      const queryFiltersArray = orFilters.map(constructFilterItem)
      const queryFilters = `filter: { or: [${queryFiltersArray.join(", ")}] }`
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            {
              allArmidInfos(${queryFilters}) {
                nodes {
                  armid
                  facilityCode
                  siteCode
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          const armidInfos = response.data.data.allArmidInfos.nodes
          const armids = armidInfos.map(info => info.armid)
          return axios({
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `
                {
                  allCalibrationSchedules(
                  filter: { armid: { in: [${armids.join(",")}] } }
                  ) {
                    nodes {
                      armid
                      dateDue
                      planId
                      assetByArmid {
                        serialId
                      }
                    }
                  }
                }
              `,
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }).then(calibrationResponse => {
            const calibrations = calibrationResponse.data.data.allCalibrationSchedules.nodes
            const mergedData = calibrations.map(calibration => {
              const armidInfo = armidInfos.find(info => info.armid === calibration.armid)
              return {
                ...calibration,
                siteCode: armidInfo?.siteCode || "",
                facilityCode: armidInfo?.facilityCode || "",
              }
            })
            return mergedData
          })
        })
        .catch(error => {
          console.error("Error retrieving Calibration Info:", error)
          throw error
        })
    },
    retrieveDqprs({ rootState }, { filters }) {
      if (filters.length === 0) {
        return Promise.resolve([])
      }

      const orFilters = filters.map(item => {
        const obj = {}
        if (item.site) obj.site = { equalTo: item.site }
        if (item.facility) obj.facilityCode = { equalTo: item.facility }
        if (item.instrument) obj.instrument = { equalTo: item.instrument }
        return obj
      })

      const constructFilterItem = (obj) => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: { equalTo: "${obj[key].equalTo}" }`)
        }
        return `{ ${parts.join(", ")} }`
      }

      const queryFiltersArray = orFilters.map(constructFilterItem)
      // const queryFilters = `filter: { or: [${queryFiltersArray.join(", ")}] }` commenting this for future use
      const queryFilters = `filter: { 
        or:[${queryFiltersArray.join(", ")}]
        dqpr2DqprByDqprno: {
          dqpr2StatusByDqprStatus: {
            statusval: { notIn: [3,6,9999,12]},
          }
        }
      }`

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
        {
          allDqpr2Dqprlocs(${queryFilters}) {
            nodes {
              dqprno
              facilityCode
              instrument
              nodeId
              site
              dqpr2DqprByDqprno {
                dqpr2StatusByDqprStatus {
                  statusdesc
                }
                dqpr2QacodeByQacode {
                  codedesc
                }
                entryDate
              }
            }
          }
        }
      `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          const dqprs = response.data.data.allDqpr2Dqprlocs.nodes
          const dqprNos = dqprs.map(dqpr => dqpr.dqprno)
          return axios({
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `
            {
              allDqpr2Commenthistories(
                filter: { dqprno: { in: [${dqprNos.join(",")}] } },
                orderBy: COMMENT_DATE_DESC
              ) {
                nodes {
                  comment
                  commentDate
                  commentKey
                  dqprno
                  personId
                }
              }
            }
          `,
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }).then(commentResponse => {
            const comments = commentResponse.data.data.allDqpr2Commenthistories.nodes
            // Group comments by dqprno
            const commentsByDqprno = comments.reduce((acc, comment) => {
              if (!acc[comment.dqprno]) {
                acc[comment.dqprno] = []
              }
              acc[comment.dqprno].push(comment)
              return acc
            }, {})
            // Merge comments with dqprs
            return dqprs.map(dqpr => ({
              ...dqpr,
              comments: commentsByDqprno[dqpr.dqprno] || [],
            }))
          })
        })
        .catch(error => {
          console.error("Error retrieving DQPR Info:", error)
          throw error
        })
    },
    retrievePmrInfo({ rootState }, { filters }) {
      if (!filters || filters.length === 0) {
        return Promise.resolve([])
      }
      const orFilters = filters.map(item => {
        const obj = {}
        if (item.siteCode) obj.reportSiteCode = { equalTo: item.siteCode }
        if (item.facilityCode) obj.reportFacilityCode = { equalTo: item.facilityCode }
        if (item.instrumentClassCode) obj.reportInstrumentClass = { equalTo: item.instrumentClassCode }
        return obj
      })
      const constructFilterItem = (obj) => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: { equalTo: "${obj[key].equalTo}" }`)
        }
        return `{ ${parts.join(", ")} }`
      }

      const queryFiltersArray = orFilters.map(constructFilterItem)
      const queryFilters = `filter: { or: [${queryFiltersArray.join(", ")}] }`
      const graphqlQuery = `
        {
          allPmrReports(${queryFilters}) {
            nodes {
              nodeId
              reportData
              reportDataType
              reportFacilityCode
              reportFrequencyCode
              reportGroupId
              reportId
              reportInstrumentClass
              reportKey
              reportSiteCode
              reportStateCode
              reportUrl
              reportedDate
              reporterId
            }
          }
        }
      `
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: graphqlQuery,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts)
        .then(response => {
          const data = response.data.data.allPmrReports.nodes
          return data
        })
        .catch(error => {
          throw error
        })
    },
    retrieveCmrInfo({ rootState }, { filters }) {
      if (!filters || filters.length === 0) {
        return Promise.resolve([])
      }

      const orFilters = filters.map(item => {
        const obj = {}
        if (item.siteCode) obj.reportSiteCode = { equalTo: item.siteCode }
        if (item.facilityCode) obj.reportFacilityCode = { equalTo: item.facilityCode }
        if (item.instrumentClassCode) obj.instrumentCode = { equalTo: item.instrumentClassCode }
        return obj
      })

      const constructFilterItem = (obj) => {
        const parts = []
        for (const key in obj) {
          parts.push(`${key}: { equalTo: "${obj[key].equalTo}" }`)
        }
        return `{ ${parts.join(", ")} }`
      }

      const queryFiltersArray = orFilters.map(constructFilterItem)
      const queryFilters = `filter: { or: [${queryFiltersArray.join(", ")}] }`

      const graphqlQuery = `
        {
          allCmrReports(${queryFilters}) {
            nodes {
              instrumentCode
              nodeId
              reportData
              reportDataType
              reportFacilityCode
              reportId
              reportKey
              reportSiteCode
              reportStateCode
              reportUrl
              reportedDate
              reporterId
            }
          }
        }
      `
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: graphqlQuery,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      return axios(opts)
        .then(response => {
          const data = response.data.data.allCmrReports.nodes
          return data
        })
        .catch(error => {
          console.error("Error retrieving CMR Info:", error)
          throw error
        })
    },
    retrieveInstDataFromInstEndPoint() {
      return axios({
        method: "GET",
        url: `${Config.app.api}/inst`,
        withCredentials: true,
        data: {},
      })
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.error("Error:", error)
          throw error
        })
    },
  },
}

export default instrument
